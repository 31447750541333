<template>
  <div class="vx-row">
    <div class="vx-col w-full md:w-2/3">
      <VxCollapsableList :data="hosts" ref="collapsable" @filter="filter" @toggle-item="toggleCollaspe">
        <template v-slot:header="{ item }">
          <div class="flex items-center">
            <div class="flex items-center">
              <vs-avatar :src="item.host.favIconPath" size="28px" icon-pack="feather" icon="icon-link" class="m-0 mr-5" />
              <h6 class="font-bold max-w-xs truncate">{{ item.host | urlPath }}</h6>
            </div>
            <div class="flex ml-auto text-sm mr-base" v-if="item.summary.monitors">
              <span class="text-success">{{ `${item.summary.monitors.active} active` }}</span>
              <span class="text-grey ml-3">{{ `${item.summary.monitors.paused} paused` }}</span>
              <span class="text-danger ml-3">{{ `${item.summary.monitors.inactive} inactive` }}</span>
            </div>
          </div>
        </template>
        <template v-slot:content="{ item, index, data }">
          <vx-data-list
            class="vx-data-list-captures"
            v-if="data"
            :ref="`vxDataList_${index}`"
            :hoverFlat="true"
            :data="data[item.host.id] || []"
            :columns="columns"
            :search="false"
            :config="{
              disablePager: true,
              selectable: false
            }"
            @filter="filterMonitors(item.host.id, index)"
          />
        </template>
      </VxCollapsableList>
    </div>
    <div class="vx-col w-full md:w-1/3">
      <vx-field class="w-full" type="input" placeholder="Search monitoring" value="" @input="search" />
      <SubscriptionQuote class="my-base" />
      <vx-card class="mb-base" title="active">
        <template slot="title">
          <h6 class="font-semibold">Detect changes made to web pages</h6>
        </template>
        <div class="text-sm">
          <p class="mb-5">Don't miss an update, market signal, important news, or even small adjustments.</p>
          <p
            >Create an automation for each page you want to monitor. As soon as a visual change is detected, you receive a notification and you can
            easily see what has changed.</p
          >
        </div>
      </vx-card>
    </div>
  </div>
</template>

<script>
import MonitorThumbnail from '@/views/monitoring/components/MonitorThumbnail';
import SubscriptionQuote from '@/components/SubscriptionQuote';
import { debounce } from '@/plugins/helper.js';
import { mapState } from 'vuex';
import Paging from '@/entity/Paging';
export default {
  name: 'MonitoringModule',
  components: {
    SubscriptionQuote
  },
  data() {
    return {
      columns: [
        {
          component: MonitorThumbnail
        }
      ]
    };
  },
  computed: {
    ...mapState({
      hosts: state => state.captures.hosts || {}
    })
  },
  methods: {
    search: debounce(async function(query) {
      return await this.fetch({ query });
    }, 500),
    async fetch({ paging, query } = {}) {
      await this.$store.dispatch('captures/hosts', {
        params: {
          has: 'monitors',
          ...(query ? { query } : {})
        },
        paging: paging || new Paging()
      });
    },
    filter(paging) {
      this.fetch({ paging });
    },
    async filterMonitors(id, index) {
      const item = this.$refs[`vxDataList_${index}`];
      const result = await this.fetchCapturesByHostId(id, {
        paging: item.paging
      });
      this.$refs.collapsable.setContents(id, result);
    },
    async fetchCapturesByHostId(id, { paging } = {}) {
      const list = await this.$store.dispatch('monitors/list', {
        dontCommit: true,
        params: {
          'url.host.id': id
        },
        paging: paging || new Paging()
      });
      return list;
    },
    /* eslint no-unused-vars: 0 */
    async toggleCollaspe({ item, index, done }) {
      const { id } = item.host;
      const result = await this.fetchCapturesByHostId(id);
      done(id, result);
    }
  },
  async created() {
    await this.fetch();
  },
  mounted() {}
};
</script>
