<template>
  <vx-card @click.prevent.stop="$router.push(`/monitoring/${data.id}`)">
    <template name="title">
      <div class="flex justify-between">
        <div>
          <p class="font-semibold text-sm mb-2">{{ data.name || 'No Title' }}</p>
          <p v-if="data.url" class="font-light text-xs my-2 text-primary max-w-xs truncate">
            <vs-icon icon-pack="feather" icon="icon-link" class="mr-1 text-xs"></vs-icon>
            {{ data.url | urlPath }}
          </p>
          <p class="font-light text-xs text-primary uppercase">
            <vs-icon icon-pack="feather" icon="icon-globe" class="mr-1"></vs-icon>
            {{ data.type || '' }}
            <span v-if="data.type === 'sitemap'" class="ml-1 uppercase"
              >{{ ((data.summary || {}).lastRun || {}).captures || 0 }} page{{
                (((data.summary || {}).lastRun || {}).captures || 0) > 1 ? 's ' : ' '
              }}
              captured</span
            >
          </p>
        </div>
        <div class="ml-auto">
          <vs-chip :color="data.status === 'active' ? 'success' : 'danger'" class="font-semibold"
            ><span class="uppercase">{{ data.status.toUpperCase() }}</span></vs-chip
          >
        </div>
      </div>
    </template>
    <vs-divider />
    <div class="flex text-2xs">
      <span class="flex uppercase">
        <vs-icon icon-pack="feather" icon="icon-clock" class="mr-1 text-xs relative" style="top: -1px;"></vs-icon>
        {{ data.schedule | humanDescription }}
      </span>
      <span class="flex ml-5 uppercase">
        <vs-icon icon-pack="feather" icon="icon-file" class="mr-1 text-xs relative" style="top: -1px;"></vs-icon>
        {{ (data.template || {}).name || 'N/a' }}
      </span>
      <span class="flex ml-5 uppercase">
        <vs-icon icon-pack="feather" icon="icon-camera" class="mr-1 text-xs relative" style="top: -1px;"></vs-icon>
        {{ (data.summary || {}).captures || 0 }} capture
      </span>
      <span class="flex ml-5 uppercase">
        <vs-icon icon-pack="feather" icon="icon-rotate-ccw" class="mr-1 text-xs relative" style="top: -1px;"></vs-icon>
        {{ (data.summary || {}).detectedChangePercent }}% changed
      </span>

      <span class="ml-auto">
        <span v-if="data.lastCaptureDate && data.nextCaptureAfter" class="relative">
          <span
            class="hover-alpha uppercase"
            :class="{ 'hover-alpha--active': hover }"
            @mouseenter.stop="hover = true"
            @mouseleave.stop="hover = false"
            >Last Capture Ran {{ data.lastCaptureDate | fromNow }}</span
          >
          <span class="uppercase">Next Capture Runs {{ data.nextCaptureAfter | fromNow }}</span>
        </span>
        <span v-else>
          <span class="uppercase"
            >{{ data.lastCaptureDate ? 'Last' : 'Next' }} Capture {{ data.lastCaptureDate ? 'Ran' : 'Runs' }}
            {{ (data.nextCaptureAfter || data.lastCaptureDate) | fromNow }}</span
          >
        </span>
      </span>
    </div>
  </vx-card>
</template>

<script>
export default {
  props: {
    data: {
      type: Object,
      required: true
    },
    config: {
      type: Object
    }
  },
  data() {
    return {
      hover: false
    };
  },
  computed: {
    screenshot() {
      try {
        const screenshots = this.data.screenshots;
        for (let i = 0; i < screenshots.length; i++) {
          const item = screenshots[i];
          if (item.screenShotPath) return item.screenShotPath;
        }
      } catch (error) {
        return false;
      }
      return false;
    }
  },
  methods: {
    async deleteCapture() {
      await this.$store.dispatch('captures/delete', { id: this.data.id });
    }
  },
  created() {}
};
</script>

<style lang="scss" scoped>
.hover-alpha {
  position: relative;
  & + span {
    display: none;
    pointer-events: none;
  }

  &--active {
    opacity: 0;
    & + span {
      position: absolute;
      display: block;
      top: 0;
      right: 0;
      white-space: nowrap;
    }
  }
}
</style>
