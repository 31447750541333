var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"vx-row"},[_c('div',{staticClass:"vx-col w-full md:w-2/3"},[_c('VxCollapsableList',{ref:"collapsable",attrs:{"data":_vm.hosts},on:{"filter":_vm.filter,"toggle-item":_vm.toggleCollaspe},scopedSlots:_vm._u([{key:"header",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"flex items-center"},[_c('div',{staticClass:"flex items-center"},[_c('vs-avatar',{staticClass:"m-0 mr-5",attrs:{"src":item.host.favIconPath,"size":"28px","icon-pack":"feather","icon":"icon-link"}}),_c('h6',{staticClass:"font-bold max-w-xs truncate"},[_vm._v(_vm._s(_vm._f("urlPath")(item.host)))])],1),(item.summary.monitors)?_c('div',{staticClass:"flex ml-auto text-sm mr-base"},[_c('span',{staticClass:"text-success"},[_vm._v(_vm._s(((item.summary.monitors.active) + " active")))]),_c('span',{staticClass:"text-grey ml-3"},[_vm._v(_vm._s(((item.summary.monitors.paused) + " paused")))]),_c('span',{staticClass:"text-danger ml-3"},[_vm._v(_vm._s(((item.summary.monitors.inactive) + " inactive")))])]):_vm._e()])]}},{key:"content",fn:function(ref){
var item = ref.item;
var index = ref.index;
var data = ref.data;
return [(data)?_c('vx-data-list',{ref:("vxDataList_" + index),staticClass:"vx-data-list-captures",attrs:{"hoverFlat":true,"data":data[item.host.id] || [],"columns":_vm.columns,"search":false,"config":{
            disablePager: true,
            selectable: false
          }},on:{"filter":function($event){return _vm.filterMonitors(item.host.id, index)}}}):_vm._e()]}}])})],1),_c('div',{staticClass:"vx-col w-full md:w-1/3"},[_c('vx-field',{staticClass:"w-full",attrs:{"type":"input","placeholder":"Search monitoring","value":""},on:{"input":_vm.search}}),_c('SubscriptionQuote',{staticClass:"my-base"}),_c('vx-card',{staticClass:"mb-base",attrs:{"title":"active"}},[_c('template',{slot:"title"},[_c('h6',{staticClass:"font-semibold"},[_vm._v("Detect changes made to web pages")])]),_c('div',{staticClass:"text-sm"},[_c('p',{staticClass:"mb-5"},[_vm._v("Don't miss an update, market signal, important news, or even small adjustments.")]),_c('p',[_vm._v("Create an automation for each page you want to monitor. As soon as a visual change is detected, you receive a notification and you can easily see what has changed.")])])],2)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }